import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './NewsItemImages.module.css';
import LightBox from './LightBox';
import LightBoxGallery from './LightBoxGallery';
import { GatsbyImage } from 'gatsby-plugin-image';

class NewsItemImages extends React.Component {
  static propTypes = {
    images: PropTypes.arrayOf(
      PropTypes.shape({ alttext: PropTypes.string, thumb: PropTypes.string, image: PropTypes.string }),
    ),
  };
  static defaultProps = {
    images: null,
  };
  constructor(props) {
    super(props);
    this.props = props;

    // const images = this.props.images.map((node)=>);
    this.state = {
      listOfImageURLs: props.images.map((elem) => elem.image),
      lightBoxActive: false,
      activeImageIndex: -1,
    };
  }
  handleClose = () => {
    this.setState({ lightBoxActive: false });
  };
  showLightBox = (index) => {
    this.setState({
      activeImageIndex: index,
      lightBoxActive: true,
    });
  };
  render() {
    const images = this.props.images;

    return (
      <div className={styles.NewsItemImages}>
        {this.state.lightBoxActive && (
          <LightBox handleClose={this.handleClose}>
            <LightBoxGallery
              listOfImageURLs={this.state.listOfImageURLs}
              showTiles={false}
              activeImageIndex={this.state.activeImageIndex}
            />
          </LightBox>
        )}
        <div className={styles.images}>
          {images.map((image, index) => {
            if (index === 0) {
              return (
                <GatsbyImage
                  alt={image.alttext || ''}
                  image={image.image}
                  key={`NewsItemImages_${index}`}
                  onClick={() => this.showLightBox(index)}
                />
                // <img
                //   key={`NewsItemImages_${index}`}
                //   src={image.image.src}
                //   alt={image.alttext}
                //   onClick={() => this.showLightBox(index)}
                // />
              );
            }
            return (
              <GatsbyImage
                alt={image.alttext}
                image={image.image}
                key={`NewsItemImages_${index}`}
                onClick={() => this.showLightBox(index)}
              />
            );
          })}
        </div>
      </div>
    );
  }
}
export default NewsItemImages;
